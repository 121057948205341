import React from 'react';
import _ from 'lodash/fp';
import { withTranslation } from 'react-i18next';
import DatoContactInfo from '../../services/datoCampusContactInformation';
import ButtonSelector from '../ButtonSelector';
import { OptionsCampusesProps } from './interface';
import DatoFinancingOptions from '../../services/datoFinancingOptions';
import './style.scss';

const REMOTE_CAMPUS_CODE = 'rmt';

const OptionsCampuses: React.SFC<any> = (props: OptionsCampusesProps) => {
  const {
    t,
    locale,
    countrySection,
    campusState: [activeCampus],
    countryState
  } = props;

  const financingOptions = new DatoFinancingOptions().getLocaleFinancingOptions(
    locale
  );

  const campuses = new DatoContactInfo(locale).contactInformationByLanguage();
  const campusOptions = _.flow(
    _.filter(info => _.has(info.campus.code.toLowerCase(), financingOptions)),
    _.map(info => ({
      code: info.campus.code.toLowerCase(),
      label: `${info.campus.description}, ${info.country}`
    }))
  )(campuses);

  const countryOptions = _.flow(
    _.map('countries'),
    _.flatten,
    _.uniqBy('isoCode'),
    _.map(({ isoCode, name }) => ({ code: isoCode, label: name }))
  )(financingOptions[REMOTE_CAMPUS_CODE]);

  return (
    <>
      <ButtonSelector
        title={t('financing:financing.financingOptions.cta')}
        options={campusOptions}
        optionState={props.campusState}
      ></ButtonSelector>
      {activeCampus === REMOTE_CAMPUS_CODE && (
        <ButtonSelector
          id={countrySection}
          title={t('financing:financing.countryOptions.title')}
          options={countryOptions}
          optionState={countryState}
        ></ButtonSelector>
      )}
    </>
  );
};

export default withTranslation()(OptionsCampuses);
