import styled, { css } from 'styled-components';

import config from '../config';
import { Text3 } from '../atoms/body';

export default styled.section`
  margin-top: calc(4.8rem + 2.4rem);
  margin-bottom: calc(2.4rem + 6.4rem);
  text-align: center;

  & > a {
    padding-left: 4.4rem;
    padding-right: 4.4rem;
  }
`;

const TitleLayoutCSS = css`
  margin-bottom: 3.2rem;

  @media (max-width: ${config.resolutions.mobileL}) {
    margin-bottom: 1.6rem;
  }
`;

const IsaOptionCSS = css`
  background-color: #fef4e6;
  padding-top: 3.2rem;
  padding-bottom: 3.2rem;
  margin-bottom: 3.2rem;

  .last-option {
    margin-bottom: 4.8rem;
  }

  & > img,
  & > h4 {
    margin-bottom: 1.6rem;
  }

  & > p {
    margin-top: 1.2rem;
    margin-bottom: 3.2rem;
    color: ${config.palette.softGray};
    line-height: 150%;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    padding-top: 2.4rem;
    padding-bottom: 2.4rem;
    margin-bottom: 2.4rem;

    .last-option {
      margin-bottom: 3.2rem;
    }
  }
`;

const ShortDescription = styled(Text3)`
  margin-top: 1.2rem;
  font-size: 2rem;
  line-height: 150%;
  color: ${config.palette.softGray};

  a {
    text-decoration: underline;
    color: ${config.palette.darkBlue};
    font-weight: bold;
  }
  /* When there is a leading space in the link text in DatoCMS, the link comes with
  an inline style with the font weight, so this is needed to overwrite it */
  a span[style] {
    font-weight: bold !important;
  }

  a:hover {
    text-decoration: none;
    background: #e6e8e8;
  }
`;

const LongDescription = styled(ShortDescription)`
  margin-top: 3.2rem;
`;

const MoreInfo = styled.div`
  align-items: center;
  border-radius: 0.6rem;
  border: 0.2rem solid ${config.palette.orange};
  boz-sizing: border-box;
  color: ${config.palette.orange};
  font-size: 2rem;
  line-height: 160%;
  padding: 1.6rem 0;
  text-align: center;
  text-decoration: none;
  transition: 0.3s ease-in-out border;
  width: 35.6rem;
  margin-top: 3.2rem;
  cursor: pointer;

  &:hover {
    border: 0.2rem solid ${config.palette.orangeTransparent('0.3')};
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    font-size: 1.5rem;
    line-height: 130%;
    margin: 0 auto;
    margin-top: 3.2rem;
    width: 100%;
  }
`;

const FinancingOptionsLayout = styled.section`
  margin-bottom: 4.8rem;

  @media (max-width: ${config.resolutions.mobileL}) {
    margin-bottom: 3.2rem;
  }
`;

export {
  TitleLayoutCSS,
  IsaOptionCSS,
  MoreInfo,
  FinancingOptionsLayout,
  LongDescription,
  ShortDescription,
};
