import styled, { css } from 'styled-components';
import config from '../../components/config';

export default styled.section`
  margin-top: calc(4.8rem + 2.4rem);
  margin-bottom: calc(2.4rem + 6.4rem);
  text-align: center;

  & > a {
    padding-left: 4.4rem;
    padding-right: 4.4rem;
  }
`;

const LayoutPayment = css`
  margin-bottom: 4.8rem;

  & > p {
    margin-top: 1.6rem;
    color: ${config.palette.darkBlueTransparent('0.6')};
  }
`;

export { LayoutPayment };
