import styled from 'styled-components';
import config from '../config';

export default styled.button`
  cursor: pointer;
  width: 95%;
  border: 0.1rem solid rgba(45, 53, 76, 0.2);
  box-sizing: border-box;
  box-shadow: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.05);
  border-radius: 0.6rem;
  padding: 1.6rem 0;
  font-size: 1.8rem;
  line-height: 140%;
  text-align: center;
  color: ${config.palette.darkBlue};
  background-color: ${config.palette.white};
  margin-bottom: 1.6rem;

  &.active {
    background-color: ${config.palette.darkBlue};
    color: ${config.palette.white};
    border: 1px solid ${config.palette.darkBlue};
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    width: 100%;
    cursor: none;
  }
`;

const ListButtons = styled.section`
  display: grid;
  grid-template-columns: 33.34% 33.34% 33.34%;
  grid-template-rows: auto;
  margin-top: 3.2rem;

  button:nth-child(3n + 2) {
    justify-self: center;
  }

  button:nth-child(3n) {
    justify-self: end;
  }

  @media (max-width: ${config.resolutions.mobileL}) {
    grid-template-columns: 100%;
  }
`;

const TextChange = styled.p`
  display: none;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 150%;
  color: ${config.palette.lightBlue};
  margin-top: 0.8rem;
  @media (max-width: ${config.resolutions.mobileL}) {
    display: block;
  }
`;

export { ListButtons, TextChange };
