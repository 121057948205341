import React from 'react';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import uniqid from 'uniqid';
import Layout from '../atoms/layout';
import { Title2 } from '../atoms/headers';
import Button, { ListButtons } from './ButtonSelector';
import { ButtonSelectorProps } from './interface';
import './style.scss';

const ButtonSelector: React.SFC<any> = (props: ButtonSelectorProps) => {
  const { options, title, optionState, ...others } = props;
  const [selectedOption, selectOption] = optionState;

  return (
    <Layout
      className={classNames({
        optionsSelector: true
      })}
      {...others}
    >
      <Title2 as="h2">{title}</Title2>
      <ListButtons>
        {options.map(option => (
          <Button
            className={classNames({
              active: option.code === selectedOption
            })}
            onClick={(): void => {
              selectOption(option.code);
            }}
            key={uniqid()}
          >
            {option.label}
          </Button>
        ))}
      </ListButtons>
    </Layout>
  );
};

export default withTranslation()(ButtonSelector);
