import _ from 'lodash';
import React, { useState } from 'react';
import classNames from 'classnames';
import { Title1, Title2 } from '../atoms/headers';
import { Text3 } from '../atoms/body';
import Layout from '../atoms/layout';
import {
  TitleLayoutCSS,
  IsaOptionCSS,
  FinancingOptionsLayout,
  MoreInfo,
  LongDescription,
  ShortDescription,
} from './CampusFinancingOptions';
import { emitGetMoreInfoEvent } from './ga_events';
import { withTranslation } from 'react-i18next';
import {
  CampusFinancingOptionsProps,
  FinancingOption,
  FinancingOptionExtended,
} from './intefaces';
import { replaceSpecialCharacters } from '../../lib/utils';
import ExtraInfo from './ExtraInfo';
import DatoFinancingOptions from '../../services/datoFinancingOptions';

import FinancialGetMoreInfoForm from '../FinancialGetMoreInfoForm';

const CampusFinancingOptions: React.SFC<any> = (
  props: CampusFinancingOptionsProps
) => {
  const { sectionId, campus, country, t, i18n } = props;
  const language = i18n.language;
  const [showFormById, updtateShowFormById] = useState('');
  const financingOptions = new DatoFinancingOptions().getCampusFinancingOptions(
    language,
    campus,
    country
  );

  return (
    <>
      <Layout id={sectionId} extend={TitleLayoutCSS}>
        <Title1 as="h2">
          {t('financing:financing.financingOptions.paymentDetails')}
        </Title1>
      </Layout>
      <FinancingOptionsLayout>
        {financingOptions.map(
          ({ title, short_description, long_description }) => {
            const id = replaceSpecialCharacters(title);

            return (
              <Layout
                extend={IsaOptionCSS}
                id={id}
                key={`financing-option-${campus}-${id}`}
              >
                <Title2 as="h3">{title}</Title2>
                <ShortDescription
                  as="div"
                  dangerouslySetInnerHTML={{ __html: short_description }}
                ></ShortDescription>
                {long_description && (
                  <LongDescription
                    as="div"
                    dangerouslySetInnerHTML={{ __html: long_description }}
                  />
                )}
                {showFormById !== id ? (
                  <MoreInfo
                    onClick={(): void => {
                      emitGetMoreInfoEvent(id);
                      updtateShowFormById(id);
                    }}
                  >
                    {t(
                      'financial-get-more-info-form:FinancialGetMoreInfoForm.button.label'
                    )}
                  </MoreInfo>
                ) : (
                  <FinancialGetMoreInfoForm
                    optionTitle={title}
                    campus={campus}
                    country={country}
                    formClass="financial-get-more-info-fin-page"
                  />
                )}
              </Layout>
            );
          }
        )}
      </FinancingOptionsLayout>
    </>
  );
};

export default withTranslation()(CampusFinancingOptions);
