import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import _ from 'lodash';
import { Box } from '@chakra-ui/react';

import withI18next from '../../i18n/TemplateI18n';
import Header from '../../components/Header';
import PageTitle from '../../components/PageTitle';
import Footer from '../../components/Footer';
import OptionsCampuses from '../../components/OptionsCampuses';
import Layout from '../../components/atoms/layout';
import { Title1 } from '../../components/atoms/headers';
import { Text3 } from '../../components/atoms/body';
import CampusFinancingOptions from '../../components/CampusFinancingOptions';
import MoreQuestions from '../../components/MoreQuestions';
import { LayoutPayment } from './AccessIronhack';
import AccessIronhackProps from '../../interface/template';
import { documentDefined, windowDefined } from '../../lib/utils';
import { SaveCampusVisited } from '../../storage/provider';
import DataLayer from '../../components/DataLayer';
import { emitCampusSelectEvent, emitCountrySelectEvent } from './ga_events';
import BlogArticlesSample from '../../components/BlogArticlesSample';
import Banner from '../../components/Banner';
import useUserLocation from '../../hooks/useUserLocation';

const REMOTE_CAMPUS_CODE = 'rmt';

const AccessIronhack: React.SFC<any> = (props: AccessIronhackProps) => {
  const {
    t,
    pageContext: { locale, pageName },
  } = props;
  const { userLocation } = useUserLocation();

  const [activeCampus, setActiveCampus] = useState('');
  const [activeCountry, setActiveCountry] = useState('');
  const [dataLayer, updateDataLayer] = useState({});
  const [dataLayerIsPopulated, setDataLayerIsPopulated] = useState(false);

  const remoteSelected = activeCampus === REMOTE_CAMPUS_CODE;
  const visibleFinancingOptions =
    (activeCampus && !remoteSelected) || (remoteSelected && activeCountry);

  useEffect(() => {
    activeCampus && emitCampusSelectEvent(activeCampus);
    updateDataLayer({ campusCode: activeCampus });
  }, [activeCampus]);

  useEffect(() => {
    activeCountry && emitCountrySelectEvent(activeCountry);
  }, [activeCountry]);

  useEffect(() => {
    if (!_.isEmpty(dataLayer)) setDataLayerIsPopulated(true);
  }, [dataLayer]);

  const goToSection = (sectionId: string): void => {
    if (windowDefined() && documentDefined()) {
      const section = document.getElementById(sectionId);
      if (section) {
        window.scroll({
          left: 0,
          top: section.offsetTop,
          behavior: 'smooth',
        });
      }
    }
  };

  useEffect(() => {
    const targetSection = visibleFinancingOptions
      ? 'financing-options'
      : 'country-options';
    goToSection(targetSection);
    SaveCampusVisited(activeCampus);
  }, [activeCampus, activeCountry]);

  return (
    <>
      <Box>{userLocation?.country === 'NL' && <Banner type="stap" />}</Box>
      {dataLayerIsPopulated && <DataLayer data={dataLayer} extended />}
      <Header pageName={pageName} showBackground>
        <PageTitle
          subtitle={t('financing:financing.mainHeadline.description')}
          title={t('financing:financing.mainHeadline.title')}
        />
      </Header>
      <Layout extend={LayoutPayment}>
        <Title1 as="h2">
          {t('financing:financing.financingOptions.title')}
        </Title1>
        <Text3 as="p">
          {t('financing:financing.financingOptions.description')}
        </Text3>
      </Layout>

      <OptionsCampuses
        campusState={[activeCampus, setActiveCampus]}
        countrySection="country-options"
        countryState={[activeCountry, setActiveCountry]}
        locale={locale}
      />

      {visibleFinancingOptions && (
        <>
          <CampusFinancingOptions
            campus={activeCampus}
            country={remoteSelected && activeCountry}
            sectionId="financing-options"
          />
          <MoreQuestions />
        </>
      )}
      <BlogArticlesSample category="financing_options" />
      <Footer page={pageName} />
    </>
  );
};

export const query = graphql`
  query ($locale: String!) {
    locales: allLocale(
      filter: {
        lng: { eq: $locale }
        ns: {
          regex: "/(menu)|(financing)|(financing-ams)|(financing-ber)|(financing-lis)|(financing-mex)|(financing-mia)|(financing-par)|(financing-sao)|(financing-spain)|(financing-rmt)|(more-questions)|course-web-dev-rmt|(seo)|(breadcrumbs)|(banner)|(financial-get-more-info-form)|(application-form)|(blog)/"
        }
      }
    ) {
      ...TranslationFragment
    }
  }
`;

export default withI18next()(AccessIronhack);
