import { updateDataLayer } from '../../lib/datalayer';

function emitCampusSelectEvent(campus: string): void {
  updateDataLayer({
    event: 'gaEvent',
    eventCategory: 'navigation',
    eventAction: 'click button',
    eventLabel: `financing options::${campus}`
  });
}

function emitCountrySelectEvent(country: string): void {
  updateDataLayer({
    event: 'gaEvent',
    eventCategory: 'navigation',
    eventAction: 'click button',
    eventLabel: `financing options::remote::${country.toLowerCase()}`
  });
}

export { emitCampusSelectEvent, emitCountrySelectEvent };
