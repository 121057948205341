import { updateDataLayer } from '../../lib/datalayer';

function emitGetMoreInfoEvent(financingOption: string) {
  updateDataLayer({
    event: 'gaEvent',
    eventCategory: 'navigation',
    eventAction: 'click button',
    eventLabel: `${financingOption}::open form::get more information`
  });
}

export { emitGetMoreInfoEvent };
