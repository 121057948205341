import { graphql, useStaticQuery } from 'gatsby';
import _ from 'lodash/fp';

import { webLanguage } from './datoUtilities';

interface FinancingOption {
  title: string;
  countries: [{ isoCode: string; name: string }];
  short_description: string;
  long_description: string;
}

export default class DatoFinancingOptions {
  private financingOptions: Record<string, Record<string, FinancingOption[]>>;

  public constructor() {
    this.financingOptions = this.getAllFinancingOptions();
  }

  public getLocaleFinancingOptions(
    locale: string
  ): Record<string, FinancingOption[]> {
    return _.getOr({}, locale, this.financingOptions);
  }

  public getCampusFinancingOptions(
    locale: string,
    campus: string,
    country: string
  ): FinancingOption[] {
    let financingOptions = this.financingOptions[locale][campus] || [];

    if (country) {
      financingOptions = _.filter(
        (fo) => _.some((c) => c.isoCode === country, fo.countries),
        financingOptions
      );
    }

    return _.reverse(_.filter((fo) => fo.title, financingOptions));
  }

  private getAllFinancingOptions(): Record<
    string,
    Record<string, [FinancingOption]>
  > {
    const { allDatoCmsFinancingOption } = useStaticQuery(
      graphql`
        query {
          allDatoCmsFinancingOption {
            edges {
              node {
                campuses {
                  code
                }
                title
                shortDescription
                longDescription
                position
                countries {
                  isoCode
                  name
                }
                locale
              }
            }
          }
        }
      `
    );
    return _.flow(
      _.getOr([], 'edges'),
      _.map('node'),
      _.sortBy('position'),
      _.reduce(
        (
          byCampusAndLocale,
          {
            locale,
            title,
            campuses,
            shortDescription,
            longDescription,
            countries,
          }
        ) => {
          const fincancingOptionLocale = webLanguage(locale);
          _.forEach(({ code }) => {
            byCampusAndLocale = _.update(
              `${fincancingOptionLocale}.${code.toLowerCase()}`,
              (financingOptions) =>
                _.concat(
                  {
                    title,
                    short_description: shortDescription,
                    long_description: longDescription,
                    countries,
                  },
                  _.defaultTo([], financingOptions)
                ),
              byCampusAndLocale
            );
          }, campuses);
          return byCampusAndLocale;
        },
        {}
      )
    )(allDatoCmsFinancingOption);
  }
}
